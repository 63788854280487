// Add CSS and imports here.
.feedback-button {
  background-color: var(color-primary);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 0;
  margin-top: 0.3125rem;
  height: 44px;
  width: 133px;
}

.above-footer-elements-container {
  margin-bottom: 1.5625rem;
}

.last-updated {
  margin-top: 0;
  padding-top: 0.78125rem;
}

.va-table-margin {
  margin: 2em auto;
}

.homepage-email-update-wrapper {
  .homepage-email-input::part(form-header) {
    font-size: 1.25rem;
  }
}

.hub-icon {
  border-radius: 50%;
  height: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
}

.social-links {
  &:last-child.vads-u-margin-bottom--2 {
    margin-bottom: 0 !important;
  }
}

.other-services-links {
  &:last-child {
    margin-bottom: 0;
  }
}

.break-word-wrap {
  word-wrap: break-word;
}

.spotlight-card {
  min-width: 30%;
  word-wrap: break-word;
}

.health-online-desktop-link {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.health-online-mobile-link {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.abbreviated-day {
  display: inline-block;
  margin-right: 0;
  width: 2.5em;

  @media screen and (min-width: 481px) {
    margin-right: 5px;
  }
}

.homepage-email-update-wrapper {
  .veteran-banner-container {
    max-width: 80rem;
    margin: 0 auto;
  }
}

// START: Styles for mobile app promo banner
#alert-with-additional-info {

  *:first-child,
  *:last-child {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

//===== Playbook

// ToDo: It appears that the page-playbook.html template that uses this class is no longer used, can we remove the template and the following classes?
.va-nav-breadcrumbs--playbook {
  background: none;
  margin: 0 0 1em 0;
  padding: 0;

  h2 {
    font-size: 1em;
  }

  li {
    text-transform: none;
    margin: 0.25em 0;
    padding: 0.1em;
    display: inline-block;
    line-height: 1.15em;
    vertical-align: middle;
    font-size: 1.35em;

    &:hover {
      border-bottom: none;
      text-decoration: none;
    }
  }

  a {
    border-bottom: 3px solid var(--vads-color-white);
    text-decoration: none !important;
    color: var(--vads-color-white);
    &:hover {
      text-decoration: none;
      background: var(--color-link-default-hover);
      border-bottom: 3px solid var(--vads-color-warning);
    }
  }
}

.va-nav-breadcrumbs--playbook {
  .parent {
    padding-top: 0.5em;
    margin: 0 0 0.75em 0;
  
    &:after {
      content: " › ";
      display: inline-block;
      color: var(--vads-color-white);
      padding: 0 0.5em;
    }
  }
  
  .active {
    background: var(--vads-color-warning);
    color: var(--vads-color-black);
    font: 400 1.35em / 1.15em var(--font-family-serif);
    margin: 0.5em 0;
    padding: 0.2em;
  
    @media (min-width: 768px) {
      font-size: 1.8em;
    }
  
    &:before {
      content: "";
      padding: 0;
      margin: 0;
    }
  }
}

// START: Styles for mobile app promo banner
.smartbanner {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 84px;
  background: #f3f3f3;
  font-family: Helvetica, sans, sans-serif;

  &.smartbanner--ios .smartbanner__exit {

    &::before,
    &::after {
      background-color: #2a2929 !important;
    }
  }

  &__exit {
    position: absolute;
    top: calc(50% - 6px);
    left: 9px;
    display: block;
    margin: 0;
    width: 12px;
    height: 12px;
    border: 0;
    text-align: center;
  }

  &__exit::before,
  &__exit::after {
    position: absolute;
    width: 1px;
    height: 12px;
    background: #716f6f;
    content: " ";
  }

  &__exit::before {
    transform: rotate(45deg);
  }

  &__exit::after {
    transform: rotate(-45deg);
  }

  &__icon {
    position: absolute;
    top: 10px;
    left: 30px;
    width: 64px;
    height: 64px;
    border-radius: 15px;
    background-size: 64px 64px;
  }

  &__info {
    position: absolute;
    top: 10px;
    left: 104px;
    display: flex;
    overflow-y: hidden;
    width: 60%;
    height: 64px;
    align-items: center;
    color: #000;
  }

  &__info__title {
    font-size: 14px;
  }

  &__info__author,
  &__info__price {
    font-size: 12px;
  }

  &__button {
    position: absolute;
    top: 32px;
    right: 10px;
    z-index: 1;
    display: block;
    padding: 0 10px;
    min-width: 10%;
    border-radius: 5px;
    background: #f3f3f3;
    color: #1474fc;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
  }

  &__button__label {
    text-align: center;
  }

  &.smartbanner--android {
    background: #3d3d3d url("data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7");
    box-shadow: inset 0 4px 0 #88b131;

    .smartbanner__button__label {
      background: #112e51 !important;
    }

    .smartbanner__exit {
      left: 6px;
      margin-right: 7px;
      width: 17px;
      height: 17px;
      border-radius: 14px;
      background: #1c1e21;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .8) inset, 0 1px 1px rgba(255, 255, 255, .3);
      color: #b1b1b3;
      font-family: "ArialRoundedMTBold", Arial;
      font-size: 20px;
      line-height: 17px;
      text-shadow: 0 1px 1px #000;
    }

    .smartbanner__exit::before,
    .smartbanner__exit::after {
      top: 3px;
      left: 8px;
      width: 2px;
      height: 11px;
      background: #b1b1b3;
    }

    .smartbanner__exit:active,
    .smartbanner__exit:hover {
      color: #eee;
    }

    .smartbanner__icon {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    .smartbanner__info {
      color: #ccc;
      text-shadow: 0 1px 2px #000;
    }

    .smartbanner__info__title {
      color: #fff;
      font-weight: bold;
    }

    .smartbanner__button {
      top: 30px;
      right: 20px;
      padding: 0;
      min-width: 12%;
      border-radius: 0;
      background: none;
      box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
      color: #d1d1d1;
      font-size: 14px;
      font-weight: bold;
    }

    .smartbanner__button:active,
    .smartbanner__button:hover {
      background: none;
    }

    .smartbanner__button__label {
      display: block;
      padding: 0 10px;
      background: #42b6c9;
      background: linear-gradient(to bottom, #42b6c9, #39a9bb);
      box-shadow: none;
      line-height: 24px;
      text-align: center;
      text-shadow: none;
      text-transform: none;
    }

    .smartbanner__button__label:active,
    .smartbanner__button__label:hover {
      background: #2ac7e1;
    }
  }
}

// fix for sidenav btn
.va-sidenav-btn-close {
  background-size: 0.625rem 0.625rem;
  background-color: transparent;
  height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.375rem;
  margin: 0;
  width: .625rem;
}

@media (min-width: 767px) {
  .va-sidenav-btn-close {
    display: none;
  }
}

// END: Styles for mobile app promo banner  